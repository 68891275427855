/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import HeroBare from 'components/elements/HeroBare'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Blog
import BlogikDiensten from 'components/related/Diensten'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroBare fields={acf.banner} />

      <div className="container pb-lg-5 pb-3">
        <Blogik
          settings={{
            limit: 3,
            postType: 'diensten'
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts }) => {
              return (
                <div>
                  {hasPosts && (
                    <>
                      <BlogikDiensten posts={posts} />
                    </>
                  )}

                  {!hasPosts && (
                    <ParseContent content="Geen resultaten" />
                  )}
                </div>
              )
            }}
          </BlogConsumer>
        </Blogik>
      </div>

      <div className="py-5" />
      <div className="py-5" />
      <div className="py-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
